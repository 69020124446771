import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown, faUpload } from "@fortawesome/free-solid-svg-icons";
import { QRCodeCanvas } from "qrcode.react";
import Functions from "./function";
import { ToastContainer, toast } from "react-toastify";
import ApiUrl from "../../../../../configTools/api";
import { CSVLink } from "react-csv";
import debounce from "lodash.debounce";

const Index = () => {
  let { id } = useParams();
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvHeaders = [
    { label: "Code", key: "code" },
    { label: "Points", key: "points" },
    { label: "Exp", key: "exp" },
  ];
  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={"QR CODE for " + id}
      bgColor={"#ffffff"}
      level={"H"}
      size={100}
    />
  );
  
  const [noprints, setNoprints] = useState(1); // Debounced state
  const [inputNoprints, setInputNoprints] = useState(1); // Immediate state
  const [data, setData] = useState(null);
  const fileName = `PointsCode_${data?.name}.csv`;

  // Debounced update function for noprints (for expensive operations)
  const updateNoprints = useCallback(
    debounce((value) => {
      setNoprints(value);
    }, 500),
    []
  );

  // Handle input changes immediately
  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      setInputNoprints(value);  // Update the immediate state
      updateNoprints(value);    // Debounced state update
    }
  };

  const UploadLogo = (e) => {
    const toastid = toast.loading("Please wait ...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    Functions.UploadImage(e, (res) => {
      if (res.status) {
        Functions.Put(
          toast,
          toastid,
          id,
          {
            logo: res.image,
            bgimage: data.bgimage,
          },
          setData
        );
      } else {
        toast.update(toastid, {
          render: "Image upload failed",
          type: "error",
          isLoading: false,
          autoClose: 2000,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const UploadBgImage = (e) => {
    const toastid = toast.loading("Please wait ...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    Functions.UploadImage(e, (res) => {
      if (res.status) {
        Functions.Put(
          toast,
          toastid,
          id,
          {
            logo: data.logo,
            bgimage: res.image,
          },
          setData
        );
      } else {
        toast.update(toastid, {
          render: "Image upload failed",
          type: "error",
          isLoading: false,
          autoClose: 2000,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  useEffect(() => {
    Functions.Get(id, setData);
  }, [id]);

  useEffect(() => {
    if (data) {
      let csv = [];
      data.codes.forEach((item, index) => {
        if (index >= noprints) {
          return;
        }

        csv.push({
          code: item.code,
          points: data.points,
          exp: data.edate.slice(0, 10),
        });
      });
      setCsvData(csv);
    }
  }, [data, noprints]);

  const updateQty = async () => {
    setLoading(true);
    await Functions.UpdateQty(id, noprints);
    await Functions.Get(id, setData, noprints);
    setLoading(false);
  };

  useEffect(() => {
    if (noprints > data?.codes.length) {
      updateQty();
    }
  }, [noprints]);

  return (
    <>
      {data && (
        <div className="admin_points_activity_card_container">
          <h1>Card Generator</h1>
          <h3>{data.name}</h3>
          <div className="admin_points_activity_card_edits">
            <div className="admin_points_activity_card_edit_item">
              <label>
                <p>
                  <FontAwesomeIcon icon={faUpload} /> Upload logo
                </p>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={UploadLogo}
                />
              </label>
            </div>
            <div className="admin_points_activity_card_edit_item">
              <label>
                <p>
                  <FontAwesomeIcon icon={faUpload} /> Upload background image
                </p>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={UploadBgImage}
                />
              </label>
            </div>
          </div>
          <div className="admin_points_activity_card_display_wrapper">
            <div className="admin_points_activity_card_display_container">
              <div
                className="points_card_backgound"
                style={{
                  backgroundImage: `url('${ApiUrl.Image_URL + data.bgimage}')`,
                }}
              >
                <div className="points_card_row">
                  <div className="points_card_col">
                    <div
                      className="points_card_logo"
                      style={{
                        backgroundImage: `url('${
                          ApiUrl.Image_URL + data.logo
                        }')`,
                      }}
                    ></div>
                  </div>
                  <div className="points_card_col">
                    <div className="points_card_text">
                      <h6>{data.name}</h6>
                    </div>
                  </div>
                </div>
                <div className="points_card_spacer"></div>
                <div className="points_card_row">
                  <div className="points_card_col">
                    <div className="points_card_qrcode_container">
                      {data.codes[0].code}
                    </div>
                  </div>
                  <div className="points_card_col">
                    <div className="points_card_details">
                      <p>EXP {data.edate.slice(0, 10)}</p>
                      <h5>{data.points} Points</h5>
                      <p>Points Card</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="admin_points_activity_card_counter_wrapper">
            <div className={`admin_points_activity_card_counter_container ${loading ? 'loading' : ''}`}>
              <div className="admin_points_activity_card_counter_row">
                <button
                  onClick={() => {
                    if (inputNoprints > 0) {
                      setInputNoprints(inputNoprints - 1);
                      updateNoprints(inputNoprints - 1);
                    }
                  }}
                >
                  -
                </button>
                <input
                  type="number"
                  value={inputNoprints}
                  onChange={handleInputChange}
                  disabled={loading}
                />
                <button
                  onClick={() => {
                    setInputNoprints(inputNoprints + 1);
                    updateNoprints(inputNoprints + 1);
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <div className="admin_points_activity_card_download_wrapper">
            <div className="admin_points_activity_card_download_container">
              <div className="airiosoft_button">
                <button
                  onClick={() => {
                    window.open(
                      `/adminprints/cards?id=${id}&prints=${noprints}`
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faFileArrowDown} /> Download
                </button>
              </div>
              <div className={`airiosoft_button ${loading ? 'loading' : ''}`}>
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={fileName}
                >
                  <FontAwesomeIcon icon={faFileArrowDown} /> Export CSV
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Index;
